<template>
  <div class="d-flex flex-column align-center">
    <div class="pt-19 font-large-register loginTitle--text">
      Boost ROI on your Calls.
    </div>
  
    <div class="pt-10 font-medium pbxTextSubTitle--text text-uppercase">
      Create an account and start your free trial
    </div>
  
    <v-form class="pt-20 width--302" v-model="validForm" ref="form">
      <FormField
        v-model="formData.email"
        :rules="emailRules"
        placeholder="Email Address"
      >
        <template #message="{ key, message }">
          <portal to="registerEmail" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
    
      <FormField
        class="mt-10"
        v-model="formData.password"
        :rules="passwordRules"
        type="password"
        placeholder="Password"
      >
        <template #message="{ key, message }">
          <portal to="registerPassword" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
    
      <FormField
        class="mt-10"
        v-model="formData['password_confirmation']"
        :rules="passwordConfirmRules"
        type="password"
        placeholder="Password confirm"
      >
        <template #message="{ key, message }">
          <portal to="registerPasswordConf" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
    
      <ActionBtnForDarkBg
        class="mt-15"
        block
        type="submit"
        @click.prevent="submitForm"
        :loading="loading"
      >
        Continue
      </ActionBtnForDarkBg>
    
      <div class="pbxTextSubTitle--text font-normal mt-12">
        By continuing, you acknowledge that you read,
        understand and agree to
        <span class="linkColor--text pointer" @click="goToTerms">T&C</span> &
        <span class="linkColor--text pointer" @click="goToPrivacy">Privacy Policy.</span>
      </div>
    </v-form>
  </div>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'

import { sendEmailAndPassword } from '@/api/register-routes'
import { sendGaEvent } from '@/helper/app-helper'

export default {
  name: 'EmailAndPassword',
  inheritAttrs: false,
  components: { FormField, ActionBtnForDarkBg },
  data: () => ({
    loading: false,
    validForm: true,
    emailRules: [],
    passwordRules: [],
    passwordConfirmRules: [],
    formData: {
      email: null,
      password: null,
      'password_confirmation': null,
    }
  }),
  methods: {
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        const { success, payload, message } = await sendEmailAndPassword(this.formData)

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('setSlug', payload)
          sendGaEvent('personal-info-form', 'Personal_Information_(Goal_3)')
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
      this.passwordRules = [
        v => !!v || 'Password is required'
      ]
      this.passwordConfirmRules = [
        v => !!v || 'This field is required',
        v => v === this.formData.password || 'Passwords must match'
      ]
    },
    goToTerms() {
      window.open('https://dialics.com/terms', '_blank')
    },
    goToPrivacy() {
      window.open('https://dialics.com/privacy', '_blank')
    },
  },
}
</script>
