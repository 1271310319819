<template>
  <div class="d-flex flex-column align-center">
    <div class="pt-19 font-large-register loginTitle--text">
      Card details
    </div>
    
    <div class="pt-10 pbxTextSubTitle--text text-uppercase">
      <div class="font-medium text-center">enter your card details to start</div>
      <div class="font-medium text-center">driving calls</div>
    </div>
    
    <v-form class="pt-15 width--302" v-model="validForm" ref="form">
      <div>
        <input type="hidden" name="payment_method" class="payment-method">
        <div class="credit-card-field creditCardBg p-relative">
          <div id="card-element"></div>

          <v-progress-linear
            :active="stripeLoading"
            :indeterminate="stripeLoading"
            absolute
            bottom
            height="2"
            color="primary"
          ></v-progress-linear>
        </div>
      </div>
      
      <ActionBtnForDarkBg
        class="mt-10"
        block
        type="submit"
        @click.prevent="submitForm"
        :loading="loading"
      >
        Start your trial
      </ActionBtnForDarkBg>
      
      <div class="d-flex justify-center align-center pbxTextSubTitle--text font-regular-m  mt-8">
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="var(--v-pbxTextSubTitle-base)" d="M8.00032 0.833374C5.47307 0.833374 3.41699 2.88946 3.41699 5.41671V8.16671H2.50033C2.0141 8.16671 1.54778 8.35986 1.20396 8.70368C0.860146 9.04749 0.666992 9.51381 0.666992 10V17.3334C0.666992 17.8196 0.860146 18.2859 1.20396 18.6297C1.54778 18.9736 2.0141 19.1667 2.50033 19.1667H13.5003C13.9866 19.1667 14.4529 18.9736 14.7967 18.6297C15.1405 18.2859 15.3337 17.8196 15.3337 17.3334V10C15.3337 9.51381 15.1405 9.04749 14.7967 8.70368C14.4529 8.35986 13.9866 8.16671 13.5003 8.16671H12.5837V5.41671C12.5837 2.88946 10.5276 0.833374 8.00032 0.833374ZM5.25032 5.41671C5.25032 3.90054 6.48416 2.66671 8.00032 2.66671C9.51649 2.66671 10.7503 3.90054 10.7503 5.41671V8.16671H5.25032V5.41671ZM8.91699 15.2461V17.3334H7.08366V15.2461C6.76318 15.0626 6.50584 14.7863 6.34558 14.4535C6.18533 14.1208 6.12967 13.7473 6.18595 13.3823C6.24223 13.0173 6.40781 12.678 6.66086 12.409C6.9139 12.14 7.24253 11.954 7.60341 11.8755C7.87146 11.8163 8.14939 11.8179 8.41672 11.8804C8.68404 11.9428 8.93394 12.0645 9.14798 12.2364C9.36202 12.4083 9.53474 12.6261 9.65342 12.8736C9.77209 13.1212 9.83368 13.3922 9.83366 13.6667C9.83313 13.9873 9.74812 14.3021 9.58719 14.5794C9.42626 14.8567 9.1951 15.0866 8.91699 15.2461Z"/>
        </svg>
  
        <span class="pl-5 pt-1">Secure & Encrypted</span>
      </div>
    </v-form>
  </div>
</template>

<script>
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'

import { getBillingPrepare, savePaymentMethod } from '@/api/payment-routes'

import { loadStripe } from '@stripe/stripe-js/pure'
import { sendGaEvent } from '@/helper/app-helper'

export default {
  name: 'CardDetails',
  inheritAttrs: false,
  components: { ActionBtnForDarkBg },
  data: () => ({
    loading: false,
    stripeLoading: true,
    validForm: true,
    pulishableKey: null,
    token: null,
    stripeElement: null,
  }),
  computed: {
    stripe() {
      return loadStripe(this.pulishableKey, { locale: 'en' })
    },
    stripeStyle() {
      return {
        base: {
          color: this.themeIsDark ? '#dadada' : '#fafafa',
          iconColor: this.themeIsDark ? '#dadada' : '#fafafa',
          fontFamily: '"Fira Sans", sans-serif',
          fontSize: '14px',
          '::placeholder': {
            color: '#ececec'
          }
        },
        invalid: {
          color: '#F06269',
          iconColor: '#F06269'
        },
      }
    },
  },
  mounted() {
    sendGaEvent('initiated-checkout', 'Initiated_Checkout_(Goal_4)')
    this.loadBillingPrepare()
  },
  methods: {
    async loadBillingPrepare() {
      const { success, payload, message } = await getBillingPrepare()

      if (success) {
        this.token = payload.intent['client_secret']
        this.pulishableKey = `${payload.key}`
        this.createStripeElement()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async createStripeElement() {
      this.stripeElement = await this.stripe
      this.stripeLoading = false
      const elements = this.stripeElement.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500;700&family=Poppins:wght@600&display=swap',
        }],
      })

      this.card = elements.create('card', {style: this.stripeStyle, hidePostalCode: true})
      this.card.mount('#card-element')
    },
    async submitForm() {
      this.loading = true

      try {
        const data = await this.stripeElement.confirmCardSetup(
          this.token,
          {
            payment_method: {
              card: this.card,
            }
          }
        )

        if (data?.error) {
          this.$notify({ type: 'error-bg', duration: 15000, text: data.error.message })
          this.loading = false
        } else {
          this.setBillingProcess({
            'payment_method': data.setupIntent['payment_method']
          })
        }
      } catch (e) {
        this.$notify({ type: 'error-bg', duration: 15000, text: e.error.message })
        this.loading = false
      }
    },
    async setBillingProcess(formData) {
      const { success, payload, message } = await savePaymentMethod(formData)

      if (success) {
        sendGaEvent('confirm-checkout', 'Confirm_Checkout_(Goal_5)')
        this.$notify({ type: 'success-bg', text: message })
        this.$router.push({ name: 'Reporting' })
      } else {
        this.loading = false
        message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
  },
}
</script>
