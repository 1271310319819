<template>
  <v-container class="register-container d-flex pa-0 justify-center align-center expandPanelBg  emptyLayoutBg" fluid fill-heigh>
    <v-card
      class="emptyLayoutBg registerCard d-flex flex-column align-center py-35 px-10 width--700 ma-10"
      flat
    >
      <div class="d-flex align-center">
        <v-icon class="mr-4" size="30">$onlyLogoIcon</v-icon>

        <div class="font-logo loginNameColor--text">Dialics</div>
      </div>

      <EmailAndPassword
        v-if="step === 1"
        @setSlug="setSlug"
      />

      <CheckEmailCode
        v-if="step === 2"
        :slug="slug"
        @prevStep="prevStep"
        @nextStep="nextStep"
      />

      <UserForm
        v-if="step === 3"
        :slug="slug"
        @prevStep="prevStep"
        @nextStep="nextStep"
      />

      <PhoneNumber
        v-if="step === 4"
        :slug="slug"
        @setPhone="setPhone"
        @prevStep="prevStep"
        @nextStep="nextStep"
      />

      <CheckPhoneCode
        v-if="step === 5"
        :slug="slug"
        :phone="phone"
        @prevStep="prevStep"
        @nextStep="nextStep"
      />

<!--      <ChoosePlan-->
<!--        v-if="step === 5"-->
<!--        :slug="slug"-->
<!--        @prevStep="prevStep"-->
<!--        @nextStep="nextStep"-->
<!--      />-->

      <CardDetails
        v-if="step === 6"
        @prevStep="prevStep"
        @nextStep="nextStep"
      />
    </v-card>
  </v-container>
</template>

<script>
import EmailAndPassword from '@/components/registration/EmailAndPassword'
import CheckEmailCode from '@/components/registration/CheckEmailCode'
import UserForm from '@/components/registration/UserForm'
import PhoneNumber from '@/components/registration/PhoneNumber'
import CheckPhoneCode from '@/components/registration/CheckPhoneCode'
import ChoosePlan from '@/components/registration/ChoosePlan'
import CardDetails from '@/components/registration/CardDetails'

import { sendGaEvent } from '@/helper/app-helper'

export default {
  name: 'Registrations',
  components: { EmailAndPassword, CheckEmailCode, UserForm, PhoneNumber, CheckPhoneCode, ChoosePlan, CardDetails },
  data: () => ({
    step: 1,
    maxSteps: 6,
    slug: null,
    phone: null,
    gaUrls: [
      '/registrations/step-1-email',
      '/registrations/step-2-user-form',
      '/registrations/step-3-card-detail',
    ]
  }),
  mounted() {
    sendGaEvent('regform', 'Create_Profile_(Goal_2)')
  },
  methods: {
    setSlug(slug) {
      this.slug = slug
      this.nextStep()
    },
    setPhone(phone) {
      this.phone = phone
      this.nextStep()
    },
    nextStep() {
      if (this.step === this.maxSteps) {
        return
      }
      
      this.step = this.step + 1
    },
    prevStep() {
      if (this.step === 1) {
        return
      }
      
      this.step = this.step - 1

      if (this.step === 1) {
        sendGaEvent('personal-info-go-back-btn', 'Personal-info-go-back-btn')
      }
    },
  },
}
</script>

<style lang="scss">
.register-container {
  height: 100%;
  background-image: url(/images/full_bg_2.png);
  background-size: cover;
  background-position: center bottom 100%;
}

.registerCard {
  height: fit-content;
  &.theme--light {
    box-shadow: 0 8px 40px rgba(23, 10, 60, 0.28)!important;
  }
  
  &.theme--dark {
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.68)!important;
  }
}
</style>
