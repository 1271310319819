import axios from '@/plugins/axios'

const getPlans = () => axios.get('plans')
const sendEmailAndPassword = formData => axios.post('auth/register', formData)
const sendEmailConfirmCode = ({ slug, formData }) => axios.post(`auth/register/${slug}/email-confirm`, formData)
const resendEmailCode = slug => axios.post(`auth/register/${slug}/email-resend`)
const sendUserData = ({ slug, formData }) => axios.post(`auth/register/${slug}/user-data`, formData)
const SMSCodeRequest = ({ slug, formData }) => axios.post(`auth/register/${slug}/sms-code-request`, formData)
const sendSMSConfirmCode = ({ slug, formData }) => axios.post(`auth/register/${slug}/sms-code-confirm`, formData)
const resendSMSCode = slug => axios.post(`auth/register/${slug}/sms-code-resend`)
const sendChangedPlan = ({ slug, formData }) => axios.post(`auth/register/${slug}/plan`, formData)

export { getPlans, sendEmailAndPassword, sendEmailConfirmCode, resendEmailCode, sendUserData, SMSCodeRequest, sendSMSConfirmCode, resendSMSCode, sendChangedPlan }