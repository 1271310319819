<template>
  <div class="d-flex flex-column align-center">
    <div class="pt-19 font-large-register loginTitle--text">
      Verify you’re real
    </div>
    
    <div class="pt-10 pbxTextSubTitle--text text-uppercase">
      <div class="font-medium text-center">
        enter your mobile number to receive
      </div>
      
      <div class="font-medium text-center">
        verification code.
      </div>
    </div>
    
    <v-form class="pt-10 width--302" v-model="validForm" ref="form">
      <FormField
        v-model="formData.phone"
        :rules="rules"
        placeholder="Phone number"
        type="number"
        @keypress="onlyNumbers"
      >
        <template #message="{ key, message }">
          <portal to="registerPhone" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
      
      <div class="d-flex pt-20">
        <div class="d-flex mr-6 width--full">
          <ActionOutlinedButton
            block
            @click="goBack"
          >
            Go back
          </ActionOutlinedButton>
        </div>
        
        <div class="d-flex width--full">
          <ActionBtnForDarkBg
            type="submit"
            block
            @click.prevent="submitForm"
            :loading="loading"
          >
            Continue
          </ActionBtnForDarkBg>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'

import { SMSCodeRequest } from '@/api/register-routes'

export default {
  name: 'PhoneNumber',
  inheritAttrs: false,
  components: { FormField, ActionBtnForDarkBg, ActionOutlinedButton },
  props: {
    slug: {
      type: String,
      default: null
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      phone: null,
    },
    rules: []
  }),
  computed: {},
  mounted() {
  },
  methods: {
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        const { success, payload, message } = await SMSCodeRequest({
          formData: this.formData,
          slug: this.slug,
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('setPhone', this.formData.phone)
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    goBack() {
      this.$emit('prevStep')
    },
  },
}
</script>
