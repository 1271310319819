<template>
  <div class="d-flex flex-column align-center">
    <div class="pt-19 font-large-register loginTitle--text">
      Choose a plan
    </div>
    
    <div class="pt-10 pbxTextSubTitle--text font-medium text-center">
      Start with 7-day free trial
    </div>
    
    <div class="mt-25 container-plans" v-if="plans.length">
      <div class="card-out primary rounded-12"></div>
      
      <div class="card-plan loginTitle rounded-12 pa-20">
        <div class="font-big primary--text">{{ plans[0].name }}</div>
        
        <div class="pt-9">
          <span class="pr-5 font-logo-m">${{ plans[0].price }}</span>
          <span class="font-regular pbxTextSubTitle--text">per month</span>
        </div>
        
        <div class="pt-9 font-regular pbxTextSubTitle--text">
          {{ plans[0].description }}
        </div>
        
        <div class="primary rounded-5 pointer mt-11 text-center font-normal height--48 d-flex align-center justify-center" @click="submitForm">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="white"
            size="24"
            width="2"
          />

          <div v-else>Select</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { getPlans, sendChangedPlan } from '@/api/register-routes'

import { setAccessToken, getAccountId, setAccountId } from '@/helper/auth-helper'

export default {
  name: 'ChoosePlan',
  inheritAttrs: false,
  props: {
    slug: {
      type: String,
      default: null
    },
  },
  data: () => ({
    loading: false,
    plans: [],
    formData: {
      plan: 1,
    },
  }),
  computed: {},
  mounted() {
    this.loadPans()
  },
  methods: {
    ...mapMutations({
      setAccounts: authTypes.mutations.SET_ACCOUNTS,
      setUser: authTypes.mutations.SET_USER,
      setAccountRole: authTypes.mutations.SET_ACCOUNT_ROLE,
      setAccountTimezone: authTypes.mutations.SET_ACCOUNT_TIMEZONE,
    }),
    ...mapActions({
      checkUserSubAccount: authTypes.actions.CHECK_USER_SUB_ACCOUNT,
      loadRoles: authTypes.actions.GET_LOAD_ROLES,
    }),
    async loadPans() {
      const { success, payload, message } = await getPlans()
      
      if (success) {
        this.plans = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async submitForm() {
      if (this.loading) {
        return
      }

      this.loading = true
      const { success, payload, message } = await sendChangedPlan({
        formData: this.formData,
        slug: this.slug,
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        setAccessToken(payload['access_token'])
        this.loadRoles()
        this.setAccounts(payload.accounts)
        this.setUser(payload.user)
        this.checkAccount(payload.accounts)
        this.$nextTick(() => {
          this.$emit('nextStep')
        })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },

    checkAccount(accounts) {
      const currentAccount = accounts.find(account => account.slug === getAccountId())
      if (!currentAccount) {
        setAccountId(accounts[0].slug)
      }

      if (accounts[0].pivot['role_id'] === 4) {
        this.setAccountRole('Admin')
        this.setAccountTimezone(accounts[0]['timezone_id'])
      } else {
        this.checkUserSubAccount(getAccountId())
      }
    },
  }
}
</script>

<style lang="scss">
.container-plans {
  position: relative;
  width: 264px;
  height: 284px;
  
  & .card-out {
    position: absolute;
    right: 0;
    top: 0;
    width: 70%;
    height: 95%;
  }
  
  & .card-plan {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}
</style>
