<template>
  <div class="d-flex flex-column align-center ">
    <div class="pt-19 font-large-register loginTitle--text">
      Some information about you
    </div>
    
    <v-form class="pt-20 width--302" v-model="validForm" ref="form">
      <FormField
        v-model="formData['first_name']"
        :rules="rules"
        placeholder="First name"
      >
        <template #message="{ key, message }">
          <portal to="registerFirstName" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
      
      <FormField
        class="mt-10"
        v-model="formData['last_name']"
        :rules="rules"
        placeholder="Last name"
      >
        <template #message="{ key, message }">
          <portal to="registerLastName" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>

      <AutocompleteField
        v-model="formData.country"
        class="login-form-input mt-10"
        :items="countries"
        item-text="name"
        item-value="id"
        :rules="rules"
        placeholder="Country"
        checkBg
      >
        <template #message="{ key, message }">
          <portal to="regCountry" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </AutocompleteField>
  
      <FormField
        class="mt-10"
        v-model="formData.company"
        :rules="rules"
        placeholder="Company"
      >
        <template #message="{ key, message }">
          <portal to="registerCompany" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>

<!--      <FormField-->
<!--        class="mt-10"-->
<!--        v-model="formData.phone"-->
<!--        :rules="rules"-->
<!--        placeholder="Phone number"-->
<!--        type="number"-->
<!--        @keypress="onlyNumbers"-->
<!--      >-->
<!--        <template #message="{ key, message }">-->
<!--          <portal to="registerPhone" v-if="!!message">-->
<!--            {{ message }}-->
<!--          </portal>-->
<!--        </template>-->
<!--      </FormField>-->
      
      <FormField
        class="mt-10"
        v-model="formData.city"
        :rules="rules"
        placeholder="City"
      >
        <template #message="{ key, message }">
          <portal to="registerCity" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>

      <FormField
        class="mt-10"
        v-model="formData.address"
        :rules="rules"
        placeholder="Address"
      >
        <template #message="{ key, message }">
          <portal to="registerAddress" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>

      <FormField
        class="mt-10"
        v-model="formData.zip"
        :rules="rules"
        placeholder="ZIP"
      >
        <template #message="{ key, message }">
          <portal to="registerZIP" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
  
      <div class="d-flex pt-20">
        <div class="d-flex mr-6 width--full">
          <ActionOutlinedButton
            block
            @click="goBack"
          >
            Go back
          </ActionOutlinedButton>
        </div>
    
        <div class="d-flex width--full">
          <ActionBtnForDarkBg
            type="submit"
            block
            @click.prevent="submitForm"
            :loading="loading"
          >
            Continue
          </ActionBtnForDarkBg>
        </div>
      </div>
      
      <div class="pbxTextSubTitle--text font-normal text-center mt-12" v-if="false">
        By continuing, you acknowledge that you read,
        understand and agree to
        <span class="primary--text pointer">T&C & Privacy Policy.</span>
      </div>
    </v-form>
  </div>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'

import { mapState } from 'vuex'

import { sendUserData } from '@/api/register-routes'
import { mapActions, mapMutations } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { setAccessToken, getAccountId, setAccountId } from '@/helper/auth-helper'

export default {
  name: 'UserForm',
  inheritAttrs: false,
  components: { FormField, AutocompleteField, ActionBtnForDarkBg, ActionOutlinedButton },
  props: {
    slug: {
      type: String,
      default: null
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      'first_name': null,
      'last_name': null,
      company: null,
      address: null,
      city: null,
      zip: null,
      country: null,
    },
    rules: [],
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
    }),
  },
  mounted() {
  },
  methods: {
    ...mapMutations({
      setAccounts: authTypes.mutations.SET_ACCOUNTS,
      setUser: authTypes.mutations.SET_USER,
      setAccountRole: authTypes.mutations.SET_ACCOUNT_ROLE,
      setAccountTimezone: authTypes.mutations.SET_ACCOUNT_TIMEZONE,
    }),
    ...mapActions({
      checkUserSubAccount: authTypes.actions.CHECK_USER_SUB_ACCOUNT,
      loadRoles: authTypes.actions.GET_LOAD_ROLES,
    }),
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        const { success, payload, message } = await sendUserData({
          formData: this.formData,
          slug: this.slug,
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          // setAccessToken(payload['access_token'])
          // this.loadRoles()
          // this.setAccounts(payload.accounts)
          // this.setUser(payload.user)
          // this.checkAccount(payload.accounts)
          this.$emit('nextStep')
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    checkAccount(accounts) {
      const currentAccount = accounts.find(account => account.slug === getAccountId())
      if (!currentAccount) {
        setAccountId(accounts[0].slug)
      }

      this.checkUserSubAccount(getAccountId())
    },
    goBack() {
      this.$emit('prevStep')
    },
  },
}
</script>
