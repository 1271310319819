<template>
  <div class="d-flex flex-column align-center">
    <div class="pt-19 font-large-register loginTitle--text">
      Enter the code
    </div>
  
    <div class="pt-10 pbxTextSubTitle--text text-uppercase">
      <div class="font-medium text-center">
        we've just sent a code
      </div>
    
      <div class="font-medium text-center">
        to <span class="pbxTextTitle--text">+{{ phone }}</span>
      </div>
    </div>
    
    <v-form class="pt-10 width--302" v-model="validForm" ref="form">
      <FormField
        v-model="formData.code"
        :rules="rules"
        placeholder="Verification Code"
      >
        <template #message="{ key, message }">
          <portal to="registerEmailCode" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
      
      <div class="pbxTextInfo--text mt-10" v-if="timeOut">
        <div class="font-normal text-center">You can get a new code</div>
        <div class="font-normal text-center">in 00:{{ timeOut < 10 ? `0${timeOut}` : timeOut }}</div>
      </div>
      
      <div class="linkColor--text pointer font-normal text-center mt-12" v-if="!timeOut" @click="resendCode">
        Resend code
      </div>
      
<!--      <div class="primary&#45;&#45;text pointer font-normal text-center mt-10">-->
<!--        Doesn't receive SMS?-->
<!--      </div>-->
  
      <div class="d-flex pt-15">
        <div class="d-flex mr-6 width--full">
          <ActionOutlinedButton
            block
            @click="goBack"
          >
            Go back
          </ActionOutlinedButton>
        </div>
    
        <div class="d-flex width--full">
          <ActionBtnForDarkBg
            type="submit"
            block
            @click.prevent="submitForm"
            :loading="loading"
          >
            Continue
          </ActionBtnForDarkBg>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'

import { sendSMSConfirmCode, resendSMSCode } from '@/api/register-routes'
import { mapActions, mapMutations } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { setAccessToken, getAccountId, setAccountId } from '@/helper/auth-helper'

export default {
  name: 'CheckPhoneCode',
  inheritAttrs: false,
  components: { FormField, ActionBtnForDarkBg, ActionOutlinedButton },
  props: {
    slug: {
      type: String,
      default: null
    },
    phone: {
      type: String,
      default: null
    },
  },
  data: () => ({
    loadingResend: false,
    loading: false,
    validForm: true,
    formData: {
      code: null,
    },
    rules: [],
    timeOut: 59,
    visibleResend: false,
    timer: null,
  }),
  mounted() {
    this.setTimer()
  },
  methods: {
    ...mapMutations({
      setAccounts: authTypes.mutations.SET_ACCOUNTS,
      setUser: authTypes.mutations.SET_USER,
      setAccountRole: authTypes.mutations.SET_ACCOUNT_ROLE,
      setAccountTimezone: authTypes.mutations.SET_ACCOUNT_TIMEZONE,
    }),
    ...mapActions({
      checkUserSubAccount: authTypes.actions.CHECK_USER_SUB_ACCOUNT,
      loadRoles: authTypes.actions.GET_LOAD_ROLES,
    }),
    async resendCode() {
      if (this.loadingResend) {
        return
      }

      this.loadingResend = true

      const { success, payload, message } = await resendSMSCode(this.slug)

      if (success) {
        this.rules = []
        this.formData.code = null
        this.timeOut = 59
        this.setTimer()
        this.$notify({ type: 'success-bg', text: message })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingResend = false
    },
    setTimer() {
      this.timer = setInterval(() => {
        --this.timeOut

        if (!this.timeOut) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        const { success, payload, message } = await sendSMSConfirmCode({
          formData: this.formData,
          slug: this.slug,
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          setAccessToken(payload['access_token'])
          this.loadRoles()
          this.setAccounts(payload.accounts)
          this.setUser(payload.user)
          this.checkAccount(payload.accounts)
          this.$emit('nextStep')
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    checkAccount(accounts) {
      const manager = accounts.find(account => account.pivot['role_id'] === 1)

      setAccountId(manager.slug)

      this.checkUserSubAccount(getAccountId())
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    goBack() {
      this.$emit('prevStep')
    },
  },
}
</script>
