<template>
  <div class="d-flex flex-column align-center">
    <div class="pt-19 font-large-register loginTitle--text">
      Check your Email!
    </div>
    
    <div class="pt-10 font-medium pbxTextSubTitle--text text-uppercase">
      enter 6 digit code
    </div>
    
    <v-form class="pt-20 width--302" v-model="validForm" ref="form">
      <FormField
        v-model="formData.code"
        :rules="rules"
        placeholder="6 digit code"
      >
        <template #message="{ key, message }">
          <portal to="registerEmailCode" v-if="!!message">
            {{ message }}
          </portal>
        </template>
      </FormField>
  
      <div class="pbxTextInfo--text mt-10" v-if="timeOut">
        <div class="font-normal text-center">You can get a new code</div>
        <div class="font-normal text-center">in 00:{{ timeOut < 10 ? `0${timeOut}` : timeOut }}</div>
      </div>
  
      <div class="linkColor--text pointer font-normal text-center mt-12" v-if="!timeOut" @click="resendCode">
        Resend code
      </div>
  
<!--      <div class="primary&#45;&#45;text pointer font-normal text-center mt-10">-->
<!--        Not receiving a letter?-->
<!--      </div>-->
      
      <div class="d-flex pt-20">
        <div class="d-flex mr-6 width--full">
          <ActionOutlinedButton
            block
            @click="goBack"
          >
            Go back
          </ActionOutlinedButton>
        </div>
        
        <div class="d-flex width--full">
          <ActionBtnForDarkBg
            type="submit"
            block
            @click.prevent="submitForm"
            :loading="loading"
          >
            Continue
          </ActionBtnForDarkBg>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'

import { sendEmailConfirmCode, resendEmailCode } from '@/api/register-routes'

export default {
  name: 'CheckEmailCode',
  inheritAttrs: false,
  components: { FormField, ActionBtnForDarkBg, ActionOutlinedButton },
  props: {
    slug: {
      type: String,
      default: null
    },
  },
  data: () => ({
    loading: false,
    loadingResend: false,
    validForm: true,
    formData: {
      code: null,
    },
    rules: [],
    timeOut: 59,
    visibleResend: false,
    timer: null,
  }),
  mounted() {
    this.setTimer()
  },
  methods: {
    async resendCode() {
      if (this.loadingResend) {
        return
      }

      this.loadingResend = true

      const { success, payload, message } = await resendEmailCode(this.slug)

      if (success) {
        this.rules = []
        this.formData.code = null
        this.timeOut = 59
        this.setTimer()
        this.$notify({ type: 'success-bg', text: message })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingResend = false
    },
    setTimer() {
      this.timer = setInterval(() => {
        --this.timeOut

        if (!this.timeOut) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        const { success, payload, message } = await sendEmailConfirmCode({
          formData: this.formData,
          slug: this.slug,
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('nextStep')
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    goBack() {
      this.$emit('prevStep')
    },
  },
}
</script>
